<template>
  <div class="jumbotron jumbotron-fluid" id='landing'>
    <div class='container-fluid'>
        <div class='row align-items-center'>
            <div class='col'>
                <h2 class='display-4 text-center'>Hi, I'm Andrew Whiting</h2>
                <hr class="my-4 bg-warning" style="height:3px;border:none">
                <h4 class='text-center'>A former international finance professional turned full-stack software developer</h4>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'LandingSection',
}
</script>