<template>
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Languages</th>
        <td>Python, JavaScript, HTML/CSS</td>
      </tr>
      <tr>
        <th scope="row">Frameworks</th>
        <td>Flask, Django, Vue.JS, Kivy, KivyMD</td>
      </tr>
      <tr>
        <th scope="row">DB</th>
        <td>SQLite3, MySQL, SQLAlchemy, Google Firebase</td>
      </tr>
      <tr>
        <th scope="row">IDEs and Tools</th>
        <td>Git CLI, Jupyter Notebook, iPython, Visual Studio Code</td>
      </tr>
      <tr>
        <th scope="row">Cloud</th>
        <td>Heroku, Linode</td>
      </tr>
      <tr>
        <th scope="row">Methodologies</th>
        <td>Functional programming, OOP programming, Agile Development, Unit testing, Test-driven development</td>
      </tr>
      <tr>
        <th scope="row">Key Libraries</th>
        <td>PyTest, Numpy, Pandas, Keras, MatPlotLib, Plotly, Beautiful Soup, Requests, CV2</td>
      </tr>
    </tbody>
  </table>
</template>


<script>

export default {
  name: 'DevSkills'
}
</script>
