<template>
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Statistical Analysis</th>
        <td>Significance testing & design, Bayesian inference</td>
      </tr>
      <tr>
        <th scope="row">Financial Forecasting</th>
        <td>CapEx modeling, ARIMA, time series, exp. smoothing</td>
      </tr>
      <tr>
        <th scope="row">Data Preparation</th>
        <td>Cleaning, factorization, regex & text processing</td>
      </tr>
      <tr>
        <th scope="row">Data Visualization</th>
        <td>Simplicity, small multiples, Plotly, Matplotlib</td>
      </tr>
      <tr>
        <th scope="row">IDEs and Tools</th>
        <td>Git CLI, Jupyter Notebook, iPython, Visual Studio Code</td>
      </tr>
      <tr>
        <th scope="row">Key Libraries</th>
        <td>Numpy, Pandas, Keras, MatPlotLib, Plotly</td>
      </tr>
    </tbody>
  </table>
</template>


<script>

export default {
  name: 'DataSkills'
}
</script>
