<template>
  <div class="container" id='about'>
    <h1>Experience</h1>
    <hr class="my-4 bg-warning" style="height:3px;border:none">
    <p>My experience ...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExperienceSection',
}
</script>